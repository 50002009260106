import React, { Component } from "react";
import { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Loader from "./Components/common/Loader";

const VatomMaker = React.lazy(() => import("./Components/VatomMaker/Maker"));
const Excoup = React.lazy(() => import("./Components/Excoup/Excoup"));
const FiestaMexicana = React.lazy(() =>
  import("./Components/FiestaMexicana/FiestaMexicana")
);
const IngredientesMexicanos = React.lazy(() =>
  import("./Components/IngredientesMexicanos/IngredientesMexicanos")
);
const FirstAlwaysOn = React.lazy(() =>
  import("./Components/AlwaysOn/FirstAlwaysOn")
);
const LHDT = React.lazy(() => import("./Components/LHDT/LHDT"));
const LHDTObjeto = React.lazy(() => import("./Components/LHDT/LHDTObjeto"));
const DoritosAlive = React.lazy(() =>
  import("./Components/DoritosAlive/DoritosAlive")
);
const NFL = React.lazy(() => import("./Components/NFL/NFL"));
const UEFASabritas = React.lazy(() =>
  import("./Components/UEFASabritas/UEFASabritas")
);
const Doritos_Loot_v02 = React.lazy(() =>
  import("./Components/Doritos_Loot_v02/Doritos_Loot_v02")
);
const LaNaveDelEmperador2 = React.lazy(() =>
  import("./Components/LNDE2/LNDE2")
);
const AdvanceDynamicFace = React.lazy(() =>
  import("./Components/AdvanceDynamicFace/AdvanceDynamicFace")
);
const DummyFace = React.lazy(() => import("./Components/DummyFace/DummyFace"));
const WorkshopDynamicFace = React.lazy(() =>
  import("./Components/WorkshopDynamicFace/WorkshopDynamicFace")
);
const StrangerThings = React.lazy(() =>
  import("./Components/StrangerThings/StrangerThings")
);
const DoritosCC = React.lazy(() =>
  import("./Components/DoritosCC/DoritosCC")
);
const Oxxo = React.lazy(() =>
  import("./Components/Oxxo/Oxxo")
);
const NFTazos = React.lazy(() => import("./Components/NFTazos/NFTazos"));

const LaAnotacionPerfecta2023 = React.lazy(() =>
  import("./Components/LaAnotacionPerfecta2023/LaAnotacionPerfecta2023")
);
const DoritosVL2023 = React.lazy(() =>
  import("./Components/DoritosVl2023/DoritosVL2023")
);
const Digis = React.lazy(() =>
  import("./Components/Digis/Digis.js")
);

const Tostitosv2023 = React.lazy(() =>
  import("./Components/TostitosV2023/TostitosV2023.jsx")
);

const NFTazosRRT = React.lazy(() => import("./Components/NFTazosRRT/NFTazosRRT"));

const FastAndFurious = React.lazy(() => import("./Components/FastAndFurious/FastAndFurious"));
const LNDE2023 = React.lazy(() => import("./Components/LNDE2023/LNDE2023"));
const NFTazosPokemon = React.lazy(() => import("./Components/NFTazosPokemon/NFTazosPokemon"));

export default class App extends Component {
  render() {
    return (
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            <Route path="/dynamicface" component={AdvanceDynamicFace} />
            <Route path="/excoup" component={Excoup} />
            <Route path="/vatommaker" component={VatomMaker} />
            <Route path="/fiestamexicana" component={FiestaMexicana} />
            <Route
              path="/ingredientesmexicanos"
              component={IngredientesMexicanos}
            />
            <Route path="/alwayson/first" component={FirstAlwaysOn} />
            <Route path="/lhdt/objeto" component={LHDTObjeto} />
            <Route path="/lhdt" component={LHDT} />
            <Route path="/doritosalive" component={DoritosAlive} />
            <Route path="/nfl" component={NFL} />
            <Route path="/uefasabritas" component={UEFASabritas} />
            <Route path="/test" component={Doritos_Loot_v02} />
            <Route path="/lnde2" component={LaNaveDelEmperador2} />
            <Route path="/dummyface" component={DummyFace} />
            <Route path="/strangerthings" component={StrangerThings} />
            <Route path="/digiverse" component={WorkshopDynamicFace} />
            <Route path="/workshop_v01" component={WorkshopDynamicFace} />
            <Route path="/nftazos" component={NFTazos} />
            <Route path="/doritoscc" component={DoritosCC} />
            <Route path="/oxxo" component={Oxxo} />
            <Route path="/lap2023" component={LaAnotacionPerfecta2023} />
            <Route path="/doritosvl2023" component={DoritosVL2023} />
            <Route path="/digis" component={Digis} />
            <Route path="/tostitosv2023" component={Tostitosv2023} />
            <Route path="/nftazos_rrt" component={NFTazosRRT} />
            <Route path="/fast_and_furious" component={FastAndFurious} />
            <Route path="/lnde2023" component={LNDE2023} />
            <Route path="/nftazos-pokemon" component={NFTazosPokemon} />
          </Switch>
        </Router>
      </Suspense>
    );
  }
}
